<template>
  <div class="view-home">
    <div class="about-main">
      <div class="about-main-vector">

      </div>
      <div class="container">
        <nav class="breadcrumbs-block">
          <ul class="breadcrumbs-list">
            <li class="breadcrumbs-item"><router-link to="/">{{ $t('navbar.home') }}</router-link></li>
            <li class="breadcrumbs-item">{{ $t('navbar.about') }}</li>
          </ul>
        </nav>
        <div class="d-flex" style="margin-top: 70px">
          <div class="col-lg-4 position-relative d-xl-block d-none">
            <div class="about-main-img">
              <el-image
                  style="width: 100%; height: 100%"
                  src="/images/about-us-back.png"
                  fit="contain"></el-image>
            </div>
            <div class="about-main-back">
              <el-image
                  style="width: 100%; height: 100%"
                  src="/images/about-img.png"
                  fit="contain"></el-image>
            </div>
          </div>
          <div class="col-lg-8">
            <p class="title">
              {{ $t('navbar.about') }}
            </p>
            <p class="text">
              {{ $t('reviewsQabiletService') }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <section class="about-qabilet">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="title">
              {{ $t('aboutWhatIsThis') }}
            </div>
            <p>
              {{ $t('aboutWhatIsThis1') }}
            </p>
            <p>
              {{ $t('aboutWhatIsThis2') }}
            </p>
          </div>
          <div class="col-lg-6 about-right-img">
            <el-image
                style="width: 80%; height: 80%"
                src="/images/qabilet-about-logo.png"
                fit="contain"></el-image>
          </div>
        </div>
      </div>
    </section>

    <section class="about-desc">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="about-desc-item" style="background-color: #EBF5FF">
              <div class="title" style="color: #252525">
                {{ $t('aboutHowWork') }}
              </div>
              <p style="color: #545454" v-html="$t('aboutHowWork1')">

              </p>
              <p style="color: #545454" v-html="$t('aboutHowWork2')">

              </p>
              <router-link :to="getTestedLink" class="link" style="text-transform: uppercase">
                {{ $t('main-page.test-btn') }}
              </router-link>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="about-desc-item" style="background-color: #1090CB">
              <div class="title" style="color: #FFFFFF">
                {{ $t('aboutWhyThisNecessary') }}
              </div>
              <p style="color: #FFFFFF">
                {{ $t('aboutWhyThisNecessary1') }}
              </p>
              <p style="color: #FFFFFF" v-html="$t('aboutWhyThisNecessary2')">

              </p>
              <router-link :to="getTestedLink" class="link-right" style="text-transform: uppercase">
                {{ $t('main-page.test-btn') }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="addresses-centers">
      <div class="container">
        <router-link class="regional-centers-link" to="/centers">
          {{ $t('for-teachers.address-btn') }}
        </router-link>
      </div>
    </section>

  </div>
</template>
<script>
import {yandexMap, ymapMarker} from "vue-yandex-maps";
import pathsRu from "@/plugins/paths/ru";
import pathsKz from "@/plugins/paths/kz";

export default {
  components: {yandexMap, ymapMarker},
  data() {
    return {
      logged: false,
      hideElements:true,
      pathFile: {},
      regionsLoading: false,
      regions: [],
      selectedRegionName: this.$i18n.locale === 'ru' ? 'Выберите свой регион' : 'Өз аймағыңызды таңдаңыз',
      selectedRegion: null,
      coords: [48.005284, 66.9045434],
      selectedSurfaces: this.$selectedSurfaces,
      surfaces: [],
      surfacesFilter:[],
      getTestedLink: localStorage.getItem('token') ? "/testing" : "/signin",
    }
  },
  methods: {
    extractNumbers(str) {
      return str.replace(/\D/g, '');
    },
    selectedBillboard(billboard) {
      let data = {
        SURFACEID: billboard.surface_id,
        NETWORKID: billboard.networkid
      };
      return data;
    },
    makeSurfaceSelected: function(surface_id) {
      this.surfaces.forEach(surface => {
        if (surface.surface_id === surface_id) {
          surface.selected = true;
        }
      });
    },
    mapBalloon: function(billboard, index) {
      if(this.$i18n.locale === 'ru'){
        return `
            <p><strong>Город</strong>: ${billboard.city_ru}</p>
            <p><strong>Адрес</strong>: ${billboard.address_ru}</p>
            </div>
            `
      }
      else if(this.$i18n.locale === 'kz'){
        return `
            <p><strong>Қаласы</strong>: ${billboard.city_kz}</p>
            <p><strong>Мекен жайы</strong>: ${billboard.address_kz}</p>
            </div>
            `
      }
    },
    checkLanguage() {
      switch (this.$i18n.locale) {
        case 'ru':
          this.pathFile = pathsRu;
          break;
        case 'kz':
          this.pathFile = pathsKz;
          break;
        case 'en':
          this.pathFile = pathsRu;
          break;
        default:
          this.pathFile = pathsRu;
      }
    },
    selectRegion(event) {
      const { value } = event.target
      if(+value - 1==-1){
        this.hideElements=true
        this.surfacesFilter=this.surfaces
        return
      }
      this.selectedRegionName = this.surfaces[+value - 1][`region_${this.$i18n.locale}`];
      this.selectedRegion = this.surfaces[+value - 1]



      for (var surface=0; surface<this.surfaces.length; surface++ ){
        if(this.surfaces[surface].id===this.selectedRegion.id){
          this.hideElements=false
          this.surfacesFilter=[]
          this.surfacesFilter.push(this.surfaces[surface])
        }
      }

    },
    async getSurfaces() {
      try {
        this.regionsLoading = true;
        const res = await this.$http.get(window.API_ROOT + '/api/references/regional-centers')
        if (res.data.data) {
          this.surfaces = res.data.data
          this.surfacesFilter=this.surfaces
          for (let i = 0; i < res.data.data.length; i++) {
            const element = res.data.data[i];
            this.regions.push({
              id: element.id,
              name: element[`region_${this.$i18n.locale}`]
            })
          }
          this.regions.unshift(
              {
                id: 0,
                name: this.$t('main-page.show-all')
              }
          )
          //<option  :key="99" :value="99" >{{ $t('main-page.show-all')}}</option>
          this.selectedRegion = res.data.data[4]
        }
      } catch (error) {
        Vue.toastr({
          message: '',
          description: `${error}`,
          type: 'error'
        });
      } finally {
        this.regionsLoading = false
      }
    }
  },
  mounted() {
    this.logged = !(localStorage.getItem('token') === null || localStorage.getItem('token') === '');
    this.checkLanguage();
    this.regionsLoading = true;
    this.getSurfaces()
  },
}
</script>

<style>
.section_text {
  margin-bottom: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.selectRegion {
  background: #F9F9F9;
  border: 1px solid #E6E6E6;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 11px 23px;
  width: 100%;
  margin-bottom: 40px;
  letter-spacing: 0.2px;
  color: #343F68;
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
  font-family: "Roboto Condensed";
  font-style: normal;
  outline: none;
  width: calc(100% - 60px);
  cursor: pointer;
  margin-top: 20px;
}
.selectRegion::after {
  content: url('/images/arrow-down.svg');
  position: absolute;
  top: 13px;
  right: 20px;
  z-index: 5;
}
.btn-blue {
  color: #FFFFFF;
  background: #04C2F4;
  border-radius: 5px;
}

@media screen and (max-width: 992px){

  .section_map {
    width: 100%;
    padding: 0;
    margin-top: 40px;
  }

  .about-main-vector {
    display: none;
  }

  .about-right-img {
    display: none;
  }

  .about-qabilet {

  }

  .about-desc {

  }

  .about-main {
    height: 440px;
  }

  .about-main .text {
    font-size: 24px;
  }

  .about-desc-item .link {
    width: 100%;
    text-align: center;
  }

  .about-desc-item .link-right {
    width: 100%;
    text-align: center;
  }

  .about-desc-item {
    margin-bottom: 30px;
    align-items: flex-start;
  }

  .about-desc-item .title {
    font-size: 20px;
  }

  .about-desc-item p {
    font-size: 16px;
  }
}

</style>